(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/betslip-components/assets/javascripts/views/parts/hedges-display.js') >= 0) return;  svs.modules.push('/components/tipsen/betslip-components/assets/javascripts/views/parts/hedges-display.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  selectFullHedges,
  selectHalfHedges,
  selectRequiredHedges
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  ReactIcon
} = svs.ui;
const {
  useBetslipContext,
  Betslip
} = svs.components.tipsen.betslipComponents;
const getAriaLabel = _ref => {
  let {
    hedgeCount,
    requiredHedgeCount,
    prefixTxt,
    hasRequiredCounts
  } = _ref;
  const hedgeDescription = hedgeCount === 1 || requiredHedgeCount === 1 ? "".concat(prefixTxt, " gardering") : "".concat(prefixTxt, " garderingar");
  const requiredHegdeCountTxt = hasRequiredCounts ? " av ".concat(requiredHedgeCount) : '';
  return "".concat(hedgeCount).concat(requiredHegdeCountTxt, " ").concat(hedgeDescription);
};
const HedgesDisplay = () => {
  const {
    couponId,
    isExtraSmall,
    isMedium
  } = useBetslipContext();
  const fullHedgeCount = useSelector(state => selectFullHedges(state, couponId, 0));
  const halfHedgeCount = useSelector(state => selectHalfHedges(state, couponId, 0));
  const {
    requiredFullHedges,
    requiredHalfHedges
  } = useSelector(state => selectRequiredHedges(state, couponId));
  const hasRequiredCounts = requiredHalfHedges > 0 || requiredFullHedges > 0;
  if (isExtraSmall) {
    return React.createElement("div", {
      "aria-label": getAriaLabel({
        hedgeCount: fullHedgeCount,
        requiredHedgeCount: requiredFullHedges,
        prefixTxt: 'hel',
        hasRequiredCounts
      }),
      "aria-live": "polite",
      className: "pg_betslip_coupon__hedges"
    }, React.createElement("div", {
      className: "pg_betslip_coupon__hedge"
    }, React.createElement(ReactIcon, {
      color: "white",
      icon: "hedge-full",
      size: "100"
    }), React.createElement("span", {
      className: "pg_betslip_coupon__hedge_values"
    }, React.createElement("span", {
      className: "pg_betslip_coupon__hedge_value",
      "data-testid": "qa-betslip-wholehedges"
    }, fullHedgeCount), hasRequiredCounts && React.createElement("span", {
      "data-testid": "qa-betslip-required-wholehedges"
    }, ' ', "(", requiredFullHedges, ")"))), React.createElement("div", {
      "aria-label": getAriaLabel({
        hedgeCount: halfHedgeCount,
        requiredHedgeCount: requiredHalfHedges,
        prefixTxt: 'halv',
        hasRequiredCounts
      }),
      "aria-live": "polite",
      className: "pg_betslip_coupon__hedge"
    }, React.createElement(ReactIcon, {
      color: "white",
      icon: "hedge-half",
      size: "100"
    }), React.createElement("span", {
      className: "pg_betslip_coupon__hedge_values"
    }, React.createElement("span", {
      className: "pg_betslip_coupon__hedge_value",
      "data-testid": "qa-betslip-halfhedges"
    }, halfHedgeCount), hasRequiredCounts && React.createElement("span", {
      "data-testid": "qa-betslip-required-halfhedges"
    }, ' ', "(", requiredHalfHedges, ")"))));
  }
  return React.createElement("div", {
    className: "pg_betslip_coupon__hedges"
  }, React.createElement("div", {
    "aria-label": getAriaLabel({
      hedgeCount: fullHedgeCount,
      requiredHedgeCount: requiredFullHedges,
      prefixTxt: 'hel',
      hasRequiredCounts
    }),
    "aria-live": "polite",
    className: "pg_betslip_coupon_hedge"
  }, React.createElement("span", {
    "aria-hidden": true,
    className: "pg_betslip_coupon__hedge_title"
  }, isMedium ? 'Helgarderingar' : 'Hel', ' '), React.createElement(ReactIcon, {
    color: "white",
    icon: "hedge-full",
    size: "200"
  }), React.createElement("span", {
    className: "pg_betslip_coupon__hedge_value"
  }, React.createElement("span", {
    "data-testid": "qa-betslip-wholehedges"
  }, fullHedgeCount), hasRequiredCounts && React.createElement("span", {
    className: "pg_betslip_coupon__hedge_value__required",
    "data-testid": "qa-betslip-required-wholehedges"
  }, ' ', "(", requiredFullHedges, ")"))), React.createElement("div", {
    "aria-label": getAriaLabel({
      hedgeCount: halfHedgeCount,
      requiredHedgeCount: requiredHalfHedges,
      prefixTxt: 'halv',
      hasRequiredCounts
    }),
    "aria-live": "polite",
    className: "pg_betslip_coupon_hedge"
  }, React.createElement("span", {
    "aria-hidden": true,
    className: "pg_betslip_coupon__hedge_title"
  }, isMedium ? 'Halvgarderingar' : 'Halv', ' '), React.createElement(ReactIcon, {
    color: "white",
    icon: "hedge-half",
    size: "200"
  }), React.createElement("span", {
    className: "pg_betslip_coupon__hedge_value"
  }, React.createElement("span", {
    "data-testid": "qa-betslip-halfhedges"
  }, halfHedgeCount), hasRequiredCounts && React.createElement("span", {
    className: "pg_betslip_coupon__hedge_value__required",
    "data-testid": "qa-betslip-required-halfhedges"
  }, ' ', "(", requiredHalfHedges, ")"))));
};
Betslip.HedgesDisplay = HedgesDisplay;

 })(window);